<style scoped>
  html,
  body {
    height: 100%;
    overflow: hidden;
  }

  /*.fade-enter{*/
  /*opacity: 0;*/
  /*transform: scale(0.85, 0.85);*/
  /*}*/
  /*.fade-enter-to{*/
  /*transform: scale(1, 1);*/
  /*}*/
  /*.fade-enter-active{*/
  /*transition: 0.25s ease-out;*/
  /*}*/
  .fade-enter,
  .fade-leave {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: 0.5s ease-out;
  }

  .flip-list-enter-active,
  .flip-list-leave-active {
    transition: all 1s;
  }

  .flip-list-enter,
  .flip-list-leave-active {
    opacity: 0;
  }

  .img-enter {
    opacity: 0;
    transform: scale(0.85, 0.85);
  }

  .img-enter-to {
    transform: scale(1, 1);
  }

  .img-enter-active {
    transition: 0.35s ease-out;
  }

  .intro-enter {
    opacity: 0;
  }

  .intro-enter-to {
    opacity: 0;
  }

  .intro-enter-active {
    transition: 0.4s ease-out;
  }

  .role-aside_Online_chat {
    padding: 5px 13px 5px 9px;
    text-align: center;
    width: 243px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 16px;
    /* margin-top: 28px; */
    margin-bottom: 7px;
  }

  .role-aside-con_Online_chat>p {

    font-size: 13px;
    font-weight: 400;
    color: #666666;
  }

  .role-img {
    margin-bottom: 0 !important;
    margin-top: 20px;
  }

  .look-box.showType2 .role-look-box {
    margin-top: 0;
  }
  .role-img_ava{
    float: right!important;
  }
  .role-dialog1{
    float: right;
  }
  .role-intro-box1{
    margin-right: 70px;
    margin-left: 0!important;
  }
  .look-box.showType2 .role-look .role-intro-str1::before {
      right: -8px;
      border-left: 10px solid #fff;
      left: initial;
      border-right: initial;
  }
  /* 弹窗样式 */
.menubox {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }

  .isbtn0_btn1 {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    background: #547CFF;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    color: #FFFFFF;
    height: 29px;
    position: relative;
    z-index: 2;
    box-shadow: 0px 0px 0px 7px #fff;
    border: 0px;
  }

  .release_dialong {
    background-color: #fff;
    width: 300px;
    height: 154.5px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .release_dialong_img {
    height: 31px;
  }

  .release_dialong_img>img {
    width: 60px;
    height: 66px;
    position: absolute;
    top: -35px;
    left: 50%;
    margin-left: -30px;
  }

  .release_dialong_title {
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    color: #202020;
    text-align: center;
    margin-top: 3px;
  }

  .release_dialong_cont {
    padding: 0 32px 0 30px;
    margin-top: 8px;
    font-size: 13px;
    font-weight: bold;
    color: #202020;
  }

  .choose {
    border-top: #E7E9EE 1px solid;
    float: left;
    width: 49.83%;
    ;
    text-align: center;
    padding: 10px 0;
    font-size: 15px;
    font-weight: 400;
    color: #547CFF;
  }

  .choose:nth-of-type(1) {
    border-right: #E7E9EE 1px solid;
  }

  .dialog-bg {
    z-index: 1;
  }

  .release_dialong_choose {
    margin-top: 10px;
    overflow: hidden;
    position: relative;
        bottom: -25px;
  }
  .release_dialong_choose1{
    width: 154px;
    height: 40px;
    background: #547CFF;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    margin: 0 auto;
    margin-top: 15px;
  }
  /* 弹窗样式 */
.role-look .role-cg p, .role-aside p {
  /* min-height: 10%;
      max-height: 50%; */
      overflow: auto;
      max-height: 50%;
}
</style>
<template>
  <div class="chapter-look">
    <header-title :titleCon="chapterInfo.chapterName" @titleLeftBtn="titleFnReturn"></header-title>
    <!--<div class="chapter-look-box" id="chapterLookBox" ref="chapterLookBox" :style="{backgroundImage:'url(' + chapterInfo.chapterBg.backpicPath + ')'}">-->
    <div class="chapter-look-box" id="chapterLookBox" ref="chapterLookBox">
      <div class="chapter-bg">
        <transition name="flip-list" appear>
          <img v-if="chapterInfo.chapterBg.backpicPath !== ''" :src="chapterInfo.chapterBg.backpicPath">
        </transition>
      </div>
      <a class="look-close look-close-str" @click.stop="closeLook">关闭预览</a>
      <audio :src="chapterInfo.chapterBgm.musicPath" v-if="chapterInfo.chapterBgm !== null" autoplay loop></audio>
      <div v-if="showType === 0" class="look-box showType0" @click="read()">
        <transition-group>
          <div class="role-look" v-for="(item, index) in chapterInfo.roleList" :key="index">
            <div v-if="index === roleIndex">
              <!--角色对白-->
              <div class="role-dialog" v-if="item.contentType === 0">
                <transition name="img" appear>
                  <div class="role-img" v-if="item.ugcCharacter.ugcCharacterpicPath !== ''"
                    :style="'top:'+ roleTop + 'px'">
                    <img
                      :src="item.ugcCharacter.ugcCharacterpicPath + '?x-oss-process=image/resize,m_fixed,h_900,w_500'" />
                  </div>
                </transition>
                <transition name="intro" appear>
                  <div v-if="item.ugcCharacter.characterName !== ''">
                    <div class="role-intro-box">
                      <div><span>{{item.ugcCharacter.characterName}}</span></div>
                      <div class="role-intro-str" v-if="item.dialog !== ''">
                        <p class="intro-p">{{item.dialog}}</p>
                        <label class="hide" ref="introspan">{{item.dialog}}</label>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <!--cg-->
              <div class="role-cg" v-if="item.contentType === 1">
                <div v-if="index === 0">
                  <img :src="item.ugcCgModel.ugcCgVo.cgPath">
                </div>
                <transition name="fade" appear>
                  <div v-if="index > 0" class="role-cg-bg">
                    <img :src="item.ugcCgModel.ugcCgVo.cgPath">
                  </div>
                </transition>
                <transition name="intro" appear>
                  <p v-if="item.ugcCgModel.text !== ''">{{item.ugcCgModel.text}}</p>
                </transition>
              </div>
              <!--旁白-->
             <div class="role-aside" v-if="item.contentType === 2">
                <div class="role-aside-con">
                  <p>{{item.aside}}</p>
                </div>
              </div>
              <!-- <div class="role-aside_Online_chat" v-if="item.contentType === 2">
                <div class="role-aside-con_Online_chat">
                  <p>{{item.aside}}</p>
                </div>
              </div> -->
            </div>
          </div>
        </transition-group>
      </div>
      <div v-if="showType === 1" class="look-box showType1" @click="read()">
        <!--<transition-group name="fade" tag="p">-->
        <div class="role-look" v-for="(item, index) in chapterInfo.roleList" :key="index">
          <div v-if="index === roleIndex">
            <!--角色对白-->
            <div class="role-dialog" v-if="item.contentType === 0">
              <div class="role-img">
                <!--<img :src="item.ugcCharacter.ugcCharacterpicPath  + '?x-oss-process=image/crop,x_75,y_00,w_600,h_937'" />-->
                <transition name="fade" appear>
                  <div class="showType1Img" v-if="item.ugcCharacter.ugcCharacterpicPath !== ''">
                    <div v-if="index === roleIndex">
                      <transition name="img" appear>
                        <img
                          :src="item.ugcCharacter.ugcCharacterpicPath + '?x-oss-process=image/crop,x_100,y_10,w_550,h_750'" />
                      </transition>
                    </div>
                  </div>
                </transition>
                <transition name="intro" appear>
                  <div class="role-intro-box" v-if="item.ugcCharacter.characterName !== ''">
                    <div><span>{{item.ugcCharacter.characterName}}</span></div>
                    <div class="role-intro-str" v-if="item.dialog !== ''">
                      <p>{{item.dialog}}</p>
                      <label class="hide">{{item.dialog}}</label>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
            <!--cg-->
            <div class="role-cg" v-if="item.contentType === 1">
              <div v-if="index === 0">
                <img :src="item.ugcCgModel.ugcCgVo.cgPath">
              </div>
              <transition name="flip-list" appear>
                <div v-if="index > 0" class="role-cg-bg">
                  <img :src="item.ugcCgModel.ugcCgVo.cgPath">
                </div>
              </transition>
              <transition name="intro" appear>
                <p v-if="item.ugcCgModel.text !== ''">{{item.ugcCgModel.text}}</p>
              </transition>
            </div>
            <!--旁白-->
            <div class="role-aside" v-if="item.contentType === 2">
              <div class="role-aside-con">
                <p>{{item.aside}}</p>
              </div>
            </div>
            <!-- <div class="role-aside_Online_chat" v-if="item.contentType === 2">
              <div class="role-aside-con_Online_chat">
                <p>{{item.aside}}</p>
              </div>
            </div> -->
          </div>
        </div>
        <!--</transition-group>-->
      </div>
      <div v-if="showType === 2">
        <div class="look-box showType2" @click="read()">
          <div class="role-look-box" style="overflow: auto;" id="lookautobox">
            <div calss="role-look-auto" id="lookauto">
              <div class="role-look" v-for="(item, index) in chapterInfo.roleList" :key="index">
                <div v-if="index <= roleIndex">
                  <!--角色对白 右-->
                  <div class="role-dialog" v-if="item.contentType === 0&&item.contentLocation === 0">
                    <div class="role-img">
                      <!--<img :src="item.ugcCharacter.ugcCharacterpicPath  + '?x-oss-process=image/crop,x_75,y_00,w_600,h_937'" />-->
                      <img
                        :src="item.ugcCharacter.ugcCharacterpicPath + '?x-oss-process=image/crop,x_145,y_20,w_450,h_450'" />
                      <div class="role-intro-box">
                        <div class="role-name-str"><span>{{item.ugcCharacter.characterName}}</span></div>
                        <div class="role-intro-str">
                          <p>{{item.dialog}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                 <!-- 角色对白 左 -->
                 <div class="role-dialog role-dialog1" v-if="item.contentType === 0&&(item.contentLocation === 1||item.contentLocation === null)">
                   <div class="role-img" >
                     <img class="role-img_ava" :src="item.ugcCharacter.ugcCharacterpicPath + '?x-oss-process=image/crop,x_170,y_20,w_400,h_400'" />
                     <div class="role-intro-box role-intro-box1">
                       <div class="role-name-str"><span>{{item.ugcCharacter.characterName}}</span></div>
                       <div class="role-intro-str role-intro-str1">
                         <p>{{item.dialog}}</p>
                       </div>
                     </div>
                   </div>
                 </div>
                  <!--cg-->
                  <div class="role-cg" style="position: fixed;" v-if="item.contentType === 1">
                    <div v-if="index === roleIndex">
                      <div v-if="index === 0">
                        <img :src="item.ugcCgModel.ugcCgVo.cgPath">
                      </div>
                      <transition name="fade" appear>
                        <div v-if="index > 0" class="role-cg-bg">
                          <img :src="item.ugcCgModel.ugcCgVo.cgPath">
                        </div>
                      </transition>
                      <transition name="intro" appear>
                        <p v-if="item.ugcCgModel.text !== ''">{{item.ugcCgModel.text}}</p>
                      </transition>
                    </div>
                  </div>
                  <!--旁白-->
                  <div class="role-aside_Online_chat" v-if="item.contentType === 2">
                    <div class="role-aside-con_Online_chat">
                      <p>{{item.aside}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 214px;"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 审核通知 -->
    <div v-show="isrelease_dialong">
      <div class="menubox">
        <div class="release_dialong">
          <div class="release_dialong_img">
            <img src="../../assets/image/releaseimage.png" alt="">
          </div>
          <div class="release_dialong_cont">
            {{release_dialong_cont_text}}
          </div>
          <div class="release_dialong_choose1" v-show="release_dialong_choose === 1" @click="Continue_to">
            继续创作
          </div>
          <div class="release_dialong_choose" v-show="release_dialong_choose===2">
            <div class="choose" @click="iknow">
              取消
            </div>
            <div class="choose" @click="Work_management">
              开启并邀请
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import headerTitle from '../../components/header/header-title.vue'
export default {
  components: {
    headerTitle
  },
  data () {
    return {
      totalIndex: 0,
      roleTotalIndex: 0, // 子章节跳转到打普通章节的第几章
      roleIndex: 0, // 正在阅读第几个角色
      roleTop: '', // 顶部距离
      showType: 0, // 形象阅读
      chapterType: 0, // 是否子章节
      chapterId: '',
      nextChapterId: null,
      chapterInfo: {
        chapterName: '',
        chapterBg: {
          // backpicPath: 'http://img3.zuok.cn/down/upload/ugcBackpic/1577330484944.jpg'
        },
        chapterBgm: {},
        roleList: []
      },
      chapterSonShow: false,
      chapterIdSon: '',
      chapterInfoSon: {
        chapterName: '',
        chapterBg: {
          // backpicPath: 'http://img3.zuok.cn/down/upload/ugcBackpic/1577330484944.jpg'
        },
        chapterBgm: {},
        roleList: []
      },
      strIndex: 0,
      isrelease_dialong: false,
      release_dialong_choose: false,
      release_dialong_cont_text: '',
      sUserId: ''
    }
  },
  computed: {},
  created () {
    this.roleIndex = 0
    // document.getElementById('chapterLook').style.height = window.screen.height + 'px'
  },
  mounted () {
    // console.log(this.$route.query.chapterType)
    this.roleTopFn()
    this.axios({
      method: 'get',
      url: '/v1/previewChapter',
      params: {
        chapterId: this.$route.query.chapterId
      }
    }).then((res) => {
      // console.log(res.data.content)
      if (res.data.head.flag === 0) {
        this.showType = res.data.content.showType
        this.chapterInfo.chapterName = res.data.content.chapterTitle
        this.chapterInfo.chapterBg = res.data.content.ugcChapterContent.ugcBackpicVo || {}
        this.chapterInfo.chapterBgm = res.data.content.ugcChapterContent.ugcMusicVo || {}
        this.chapterInfo.roleList = res.data.content.ugcChapterContent.chapterDialogs
        this.chapterId = this.$route.query.chapterId
        this.nextChapterId = res.data.content.nextChapterId
        this.totalIndex = this.chapterInfo.roleList.length
      } else {
        this.$toast(res.data.head.desc)
      }
    })
    // document.getElementById('chapterLook').style.height = (window.screen.height - 46) + 'px'
  },
  updated () {
    // this.typingStr()
  },
  methods: {
    Continue_to () {
      this.isrelease_dialong = false
      this.closeLook()
    },
    iknow () {
      this.isrelease_dialong = false
    },
    Work_management () {
      this.isrelease_dialong = false
      this.axios({
        method: 'get',
        url: '/v1/book/enableCoCreate',
        params: {
          ugcBookId: this.$route.query.bookId
          // chapterId: this.$route.query.chapterId
        }
      }).then((res) => {
        // console.log(res)
        if (res.data.head.flag === 0) {
          // console.log('开启共创模式成功')
          this.$toast(res.data.head.desc)
          this.$router.push({
            path: '/invitedshare',
            query: {
              bookId: this.$route.query.bookId,
              chapterType: this.$route.query.chapterType,
              sUserId: this.$route.query.sUserId
            }
          })
        } else if (res.data.head.flag === 1) {
          this.$toast('作品已开启共创模式')
          this.$router.push({
            path: '/invitedshare',
            query: {
              bookId: this.$route.query.bookId,
              chapterType: this.$route.query.chapterType,
              sUserId: this.$route.query.sUserId
            }
          })
        }
      })
    },
    typingStr () {
      this.strIndex = 0
      this.typing()
    },
    typing () {
      // console.log(document.querySelector('.role-intro-str label'))
      // console.log(document.querySelector('.role-intro-str label').innerHTML)
      var strShow = ''
      let strHide = document.querySelector('.role-intro-str label').innerHTML
      if (this.strIndex <= strHide.length) {
        strShow = strHide.slice(0, this.strIndex++)
        document.querySelector('.role-intro-str p').innerHTML = strShow
        this.timer = setTimeout(() => {
          this.typing()
        }, 150)
      } else {
        clearTimeout(this.timer)
      }
    },
    titleFnReturn () {
      this.$router.go(-1)
    },
    goRoleIndex () {
      if (this.roleIndex < this.chapterInfo.roleList.length - 1) {
        this.chapterSonShow = false
        this.roleIndex++
        // console.log(this.roleIndex)
      } else {
        if (this.$route.query.lookType === 0) {
          // console.log(123)
          this.$toast('本章完结')
        } else if (this.$route.query.lookType === 1) {
          this.$toast('本书完结')
        }
      }
    },
    // 点击阅读
    read () {
      if (this.chapterInfo.roleList.length > 0) {
        if (this.chapterInfo.roleList[this.roleIndex].ugcSubChapter !== null) { // 是否子章节
          this.roleTotalIndex = this.roleIndex + 1
          let that = this
          that.getChapterCon(that.chapterInfo.roleList[that.roleIndex].ugcSubChapter.subChapterId, 1, 0)
          // console.log(2)
        } else {
          if (this.roleIndex < this.chapterInfo.roleList.length - 1) { // 普通章节不是最后一个角色
            this.roleIndex++
            // this.typingStr()
            // console.log(3)
            // 先获取到div的的高度
            let div1 = document.getElementById('lookautobox')
            let div2 = document.getElementById('lookauto')
            div1.scrollTop = div2.scrollHeight
            // console.log(div1.scrollTop, div2.scrollHeight)
            // var winH = this.$refs.showType2_scroll.clientHeight + 79
            // this.$refs.showType2_scroll.scrollTop = winH - 99999
            // console.log(winH)
          } else {
            this.lastReadGo()
            // console.log(4)
          }
        }
      } else {
        this.lastReadGo()
      }
      if (this.showType === 2) {
        // let div1 = document.getElementById('lookautobox')
        // let div2 = document.getElementById('lookauto')
        // div1.scrollTop = div2.scrollHeight + 50
      }
    },
    // 普通与子章节最后角色跳转判断
    lastReadGo () {
      if (this.chapterType === 1) {
        if (this.roleTotalIndex < this.totalIndex) {
          this.getChapterCon(this.chapterId, 0, this.roleTotalIndex)
        } else {
          if (parseInt(this.$route.query.lookType) === 0) {
            // console.log(123)
            this.$toast('本章完结')
          } else {
            if (this.nextChapterId === null) {
              // console.log(123)
              this.$toast('本书完结')
            } else {
              this.getChapterCon(this.nextChapterId, 0, 0)
            }
          }
        }
      } else {
        if (parseInt(this.$route.query.lookType) === 0) {
          this.axios({
            method: 'get',
            url: '/v1/look/examine',
            params: {
              ugcBookId: this.$route.query.bookId,
              chapterId: this.$route.query.chapterId
            }
          }).then((res) => {
            // console.log(res)
            if (res.data.head.flag === 0) {
              if (res.data.content.sum < '60') { // 分数判断
                this.isrelease_dialong = true
                this.release_dialong_choose = 1
                this.release_dialong_cont_text = '创世小主，此次创作你得到了' + res.data.content.sum + '分，要继续努力哦~  '
              } else if (res.data.content.sum >= '60' && res.data.content.bookCreatorCount < '3') { // 分数大于60  人数小于3
                this.isrelease_dialong = true
                this.release_dialong_choose = 2
                this.release_dialong_cont_text = '创世小主，此次创作你得到了' + res.data.content.sum + '分，快邀请小伙伴和你共创吧~'
              }
            }
          })
          // this.$toast('本章完结')
        } else {
          if (this.nextChapterId === null) {
            // console.log(123)
            this.$toast('本书完结')
          } else {
            this.getChapterCon(this.nextChapterId, 0, 0)
            this.chapterId = this.nextChapterId
          }
        }
      }
    },
    // 获取阅读页信息
    getChapterCon (chapterId, chapterType, roleIndex) {
      this.axios({
        method: 'get',
        url: '/v1/previewChapter',
        params: {
          chapterId: chapterId
        }
      }).then((res) => {
        // console.log(res.data.content)
        if (res.data.head.flag === 0) {
          this.chapterType = chapterType
          this.roleIndex = roleIndex
          this.showType = res.data.content.showType
          this.chapterInfo.chapterName = res.data.content.chapterTitle
          this.chapterInfo.chapterBg = res.data.content.ugcChapterContent.ugcBackpicVo || {}
          this.chapterInfo.chapterBgm = res.data.content.ugcChapterContent.ugcMusicVo || {}
          this.chapterInfo.roleList = res.data.content.ugcChapterContent.chapterDialogs
          if (chapterType === 0) {
            this.nextChapterId = res.data.content.nextChapterId
            this.totalIndex = res.data.content.ugcChapterContent.chapterDialogs.length
          }
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    },
    // 关闭预览
    closeLook () {
      if (this.$route.query.chapterType === '1') {
        this.$router.push({
          path: '/chapterManage',
          query: {
            bookId: this.$route.query.bookId,
            chapterType: this.$route.query.chapterType
          }
        })
      } else {
        this.$router.push({
          path: '/chapterManage',
          query: {
            bookId: this.$route.query.bookId
          }
        })
      }
    },
    // 半身像显示屏幕兼容
    roleTopFn () {
      var winW = this.$refs.chapterLookBox.clientWidth
      var winH = this.$refs.chapterLookBox.clientHeight
      if (winW * 1.7787 > winH - 20) {
        // console.log(11)
        this.roleTop = 20
      } else {
        // console.log(22)
        this.roleTop = ''
      }
      // console.log('winW：' + winW + '，winH：' + winH)
      // console.log(this.roleTop)
    }
  }
}
</script>
